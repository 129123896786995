import { BookingDurationMinutes } from './booking.model';
import { Court } from './park.model';

export type BookingDuration = {
  minutes: BookingDurationMinutes;
  display: string;
};

export const BOOKING_DURATIONS: BookingDuration[] = [
  // { minutes: 15, display: '15 minutes' },
  { minutes: 30, display: '30 minutes' },
  // { minutes: 45, display: '45 minutes' },
  { minutes: 60, display: '1 hour' },
  // { minutes: 75, display: '1 hour 15 minutes' },
  { minutes: 90, display: '1 hour 30 minutes' },
  // { minutes: 105, display: '1 hour 45 minutes' },
  { minutes: 120, display: '2 hours' },
];

export interface BookingRequest {
  email: string;
  parkId: string;
  court: Court;
  start: string;
  end: string;
  duration: BookingDurationMinutes;
}

export interface BookingConfirmationRequest {
  email: string;
  bookingId: string;
}
