import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useSetRecoilState } from 'recoil';
import { selectedSlotState, selectedCourtState } from '../recoil';
import { ParkAvailabilitySlot } from '../types';
import { convertStringToDisplayTime } from '../utils';

type SlotCardProps = {
  slot: ParkAvailabilitySlot;
  setDisplayConfirmBookingModal: (value: boolean) => void;
};

export const SlotCard = ({ slot, setDisplayConfirmBookingModal }: SlotCardProps) => {
  const setSelectedSlot = useSetRecoilState(selectedSlotState);
  const setSelectedCourt = useSetRecoilState(selectedCourtState);

  return (
    <Card
      sx={{ width: '50%', minWidth: '250px', maxWidth: '400px', margin: '10px' }}
      onClick={() => {
        setSelectedSlot(slot);
        setSelectedCourt(slot.courts[0]);
        setDisplayConfirmBookingModal(true);
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {`${convertStringToDisplayTime(slot.isoStartDateTime)} - ${convertStringToDisplayTime(
            slot.isoEndDateTime
          )}`}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          {slot.duration.display}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          {'Available Courts: ' + slot.courts.length}
        </Typography>
      </CardContent>
    </Card>
  );
};
