export enum AppRoutes {
  Account = '/account',
  AuthLogin = '/auth-login',
  Availability = '/availability',
  Cancel = '/cancel',
  Confirm = '/confirm',
  Error = '/error',
  SearchParks = '/',
  Login = '/login',
}
