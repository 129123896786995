import { useEffect, useState } from 'react';
import { SlotCard, BookingModal, DatePicker, DurationPicker, Navbar } from '../components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  bookingDurationState,
  parkAvailabilityState,
  searchDateState,
  selectedParkState,
} from '../recoil';
import { apiGet } from '../api';
import { ParkAvailability } from '../types';
import '../styles/Availability.css';

export const Availability = () => {
  const searchDate = useRecoilValue(searchDateState);
  const [parkAvailability, setParkAvailability] = useRecoilState(parkAvailabilityState);
  const bookingDuration = useRecoilValue(bookingDurationState);

  const selectedPark = useRecoilValue(selectedParkState);
  const [displayConfirmBookingModal, setDisplayConfirmBookingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchParkAvailability = async () => {
      try {
        console.log('Global state before fetchParkAvailability:');
        console.log({ searchDate, selectedPark, parkAvailability });
        if (!selectedPark) return;
        const { id: parkId } = selectedPark;
        const availability = (await apiGet(
          `/park/${parkId}/availability/${searchDate?.format('YYYY-MM-DD')}/duration/${bookingDuration?.minutes}`
        )) as ParkAvailability;
        setParkAvailability(availability);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchParkAvailability();
    console.log('Global state after fetchParkAvailability:');
    console.log({ searchDate, selectedPark, parkAvailability });
  }, [selectedPark, searchDate, bookingDuration]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="page-container">
      <Navbar />

      <div className="page-content-container">
        <div className="availability-search-container">
          <DatePicker />
          <DurationPicker />
        </div>
        {parkAvailability && parkAvailability.slots && parkAvailability.slots.length ? (
          parkAvailability?.slots.map((slot, index) => (
            <SlotCard
              key={index}
              slot={slot}
              setDisplayConfirmBookingModal={setDisplayConfirmBookingModal}
            />
          ))
        ) : (
          <div>
            <hr></hr>
            <h2>No slots available</h2>
          </div>
        )}
      </div>
      {displayConfirmBookingModal && (
        <BookingModal setDisplayConfirmBookingModal={setDisplayConfirmBookingModal} />
      )}
      {displayConfirmBookingModal && <div className="blur-bg"></div>}
    </div>
  );
};
