import { useEffect, useState } from 'react';
import { Navbar } from '../components';
import { useSearchParams } from 'react-router-dom';
import { apiPost } from '../api';
import { Booking } from '../types';
import { convertStringToDisplayTime } from '../utils';

export const ConfirmBooking = () => {
  const [searchParams] = useSearchParams();

  const [bookingConfirmation, setBookingConfirmation] = useState<Booking | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const confirmBooking = async () => {
      try {
        const bookingId = searchParams.get('bookingId');
        const email = searchParams.get('email');
        console.log('Booking ID:', bookingId);
        console.log('Email:', email);
        const data = await apiPost('/booking/confirm', { bookingId, email });
        console.log('Booking Confirmed:');
        if (!data) {
          throw new Error('Failed to confirm booking');
        }
        setBookingConfirmation(data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        setError(err.message);
      }
    };

    confirmBooking();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error || !bookingConfirmation) return <p>Error: {error}</p>;

  return (
    <div className="page-container">
      <Navbar />
      <div className="page-content-container">
        <h1>Booking Confirmed</h1>
        <table className="booking-details-table">
          <thead className="booking-details-header">
            <th className="booking-details-table-header" colSpan={2}>
              Details
            </th>
          </thead>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Confirmation #</td>
            <td className="booking-details-table-data-info">{bookingConfirmation.id}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Status</td>
            <td className="booking-details-table-data-info">{bookingConfirmation.status}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Email</td>
            <td className="booking-details-table-data-info">{bookingConfirmation.userEmail}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Park Name</td>
            <td className="booking-details-table-data-info">{bookingConfirmation.parkId}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Court #</td>
            <td className="booking-details-table-data-info">{bookingConfirmation.courtId}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Date</td>
            <td className="booking-details-table-data-info">
              {bookingConfirmation.start
                ? new Date(bookingConfirmation.start).toLocaleDateString('en-US')
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Start Time</td>
            <td className="booking-details-table-data-info">
              {bookingConfirmation.start
                ? convertStringToDisplayTime(bookingConfirmation.start)
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">End Time</td>
            <td className="booking-details-table-data-info">
              {bookingConfirmation.end
                ? convertStringToDisplayTime(bookingConfirmation.end)
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Duration in Minutes</td>
            <td className="booking-details-table-data-info">
              {bookingConfirmation.duration ? bookingConfirmation.duration : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Total Paid</td>
            <td className="booking-details-table-data-info">$0.00 {/** TODO: Add Pricing */}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
