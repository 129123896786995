import { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilValue } from 'recoil';
import { selectedCourtState, selectedParkState, selectedSlotState } from '../recoil';
import { convertStringToDisplayTime } from '../utils';
import { apiPost } from '../api';
import { BookingRequest } from '../types';
import { AppRoutes } from '../constants';
import { Link } from 'react-router-dom';
import '../styles/Modal.css';
import TextField from '@mui/material/TextField/TextField';

enum ModalState {
  Review = 'review',
  Loading = 'loading',
  Complete = 'complete',
  Error = 'error',
}

interface BookingModalProps {
  setDisplayConfirmBookingModal: (value: boolean) => void;
}

export const BookingModal = ({ setDisplayConfirmBookingModal }: BookingModalProps): JSX.Element => {
  const selectedPark = useRecoilValue(selectedParkState);
  const selectedCourt = useRecoilValue(selectedCourtState);
  const selectedSlot = useRecoilValue(selectedSlotState);

  const [modalState, setModalState] = useState<ModalState>(ModalState.Review);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [validEmail, setValidEmail] = useState<boolean>(false);

  async function handleBookingConfirmation() {
    console.log(emailAddress);
    setModalState(ModalState.Loading);
    const booking: BookingRequest = {
      email: emailAddress,
      court: selectedCourt!,
      parkId: selectedPark!.id,
      start: selectedSlot!.isoStartDateTime,
      end: selectedSlot!.isoEndDateTime,
      duration: selectedSlot!.duration.minutes,
    };
    const bookingConfirmation = await apiPost('/booking/guest-checkout', booking);
    if (!bookingConfirmation) {
      console.error('Failed to confirm booking');
      setModalState(ModalState.Error);
    } else {
      console.log(bookingConfirmation);
      setModalState(ModalState.Complete);
    }
  }

  function updateEmailAddress(event: React.ChangeEvent<HTMLInputElement>) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setEmailAddress(event.target.value);
    setValidEmail(emailRegex.test(event.target.value));
  }

  if (modalState === ModalState.Review) {
    return (
      <div className="modal-container">
        <div
          className="close-modal-icon-container"
          onClick={() => setDisplayConfirmBookingModal(false)}
        >
          <CloseRoundedIcon className="close-modal-icon" />
        </div>

        <table className="booking-details-table">
          <thead className="booking-details-header">
            <th className="booking-details-table-header" colSpan={2}>
              Booking Details
            </th>
          </thead>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Park Name</td>
            <td className="booking-details-table-data-info">{selectedPark?.name}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Court #</td>
            <td className="booking-details-table-data-info">{selectedCourt?.courtNumber}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Address</td>
            <td className="booking-details-table-data-info">{selectedPark?.addressLine}</td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title"></td>
            <td className="booking-details-table-data-info">
              {selectedPark?.city + ', ' + selectedPark?.state + ' ' + selectedPark?.zip}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Date</td>
            <td className="booking-details-table-data-info">
              {selectedSlot?.isoStartDateTime
                ? new Date(selectedSlot.isoStartDateTime).toLocaleDateString('en-US')
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Start Time</td>
            <td className="booking-details-table-data-info">
              {selectedSlot?.isoStartDateTime
                ? convertStringToDisplayTime(selectedSlot.isoStartDateTime)
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">End Time</td>
            <td className="booking-details-table-data-info">
              {selectedSlot?.isoEndDateTime
                ? convertStringToDisplayTime(selectedSlot.isoEndDateTime)
                : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Duration</td>
            <td className="booking-details-table-data-info">
              {selectedSlot?.duration ? selectedSlot.duration.display : 'Date Error'}
            </td>
          </tr>
          <tr className="booking-details-table-row">
            <td className="booking-details-table-data-title">Total Due</td>
            <td className="booking-details-table-data-info">$0.00 {/** TODO: Add Pricing */}</td>
          </tr>
        </table>

        <TextField
          onChange={updateEmailAddress}
          required
          id="outlined-required"
          label={'Email Address'}
          helperText={
            !validEmail
              ? 'Invalid Email, please enter valid email address'
              : 'Enter your email address to confirm booking'
          }
          sx={{ minWidth: '250px', maxWidth: '300px', margin: '10px' }}
        />

        <div className="booking-button-container">
          <button
            className="confirm-booking-button"
            onClick={handleBookingConfirmation}
            disabled={!validEmail}
          >
            Book Now!
          </button>
        </div>

        <p className="booking-helper-text">
          After clicking "Book Now", you will receive an email with a link to confirm booking. This
          link will be valid for 10 minutes.
        </p>
      </div>
    );
  }

  if (modalState === ModalState.Loading) {
    return (
      <div className="modal-container">
        <div
          className="close-modal-icon-container"
          onClick={() => setDisplayConfirmBookingModal(false)}
        >
          <CloseRoundedIcon className="close-modal-icon" />
        </div>

        <div className="feedback-header-bg">
          <h1 className="feedback-header">
            <b>Confirm Booking</b>
          </h1>
        </div>

        <h3 className="feedback-results-text">Submitting Booking Request...</h3>

        <CircularProgress />
      </div>
    );
  }

  if (modalState === ModalState.Complete) {
    return (
      <div className="modal-container">
        <Link to={AppRoutes.SearchParks}>
          <div className="close-modal-icon-container">
            <CloseRoundedIcon className="close-modal-icon" />
          </div>
        </Link>

        <div className="feedback-header-bg">
          <h1 className="feedback-header">
            <b>Verification Email Sent</b>
          </h1>
        </div>

        <p className="feedback-helper-text">
          Please check your email and click confirm to complete your booking. Your booking will be
          held for 10 minutes.
        </p>
      </div>
    );
  }

  if (modalState === ModalState.Error) {
    return (
      <div className="modal-container">
        <Link to={AppRoutes.SearchParks}>
          <div className="close-modal-icon-container">
            <CloseRoundedIcon className="close-modal-icon" />
          </div>
        </Link>

        <div className="feedback-header-bg">
          <h1 className="feedback-header">
            <b>Your booking request failed</b>
          </h1>
        </div>

        <p className="feedback-helper-text">
          The slot you are viewing may have been booked by another user. Please try again.
        </p>
      </div>
    );
  }

  return <h1>Error: Incorrect Feedback Data Type</h1>;
};
