const CLIENT_LOCAL_PORT = 3000;
const CLIENT_LOCAL_URL = `http://localhost:${CLIENT_LOCAL_PORT}`;
const CLIENT_PROD_URL = 'https://gamesetbook.com';
const SERVER_LOCAL_URL = 'http://localhost:3001';
const SERVER_PROD_URL = 'https://api.gamesetbook.com';

export const CLIENT_URL =
  process.env.REACT_APP_STAGE === 'prod' ? CLIENT_PROD_URL : CLIENT_LOCAL_URL;
export const SERVER_URL: string =
  process.env.REACT_APP_STAGE === 'prod' ? SERVER_PROD_URL : SERVER_LOCAL_URL;
