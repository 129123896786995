const { REACT_APP_STAGE } = process.env;

console.log('REACT_APP_STAGE', REACT_APP_STAGE);

export const APP_URL =
  REACT_APP_STAGE === 'dev' ? 'http://localhost:3000' : 'https://gamesetbook.com';
export const COGNITO_DOMAIN =
  REACT_APP_STAGE === 'dev'
    ? 'https://game-set-book-dev.auth.us-east-1.amazoncognito.com'
    : 'https://auth.gamesetbook.com';

export const cognitoConfig = {
  REGION: 'us-east-1', // your region
  USER_POOL_ID: 'us-east-1_aIFsJnvrN', // your user pool id
  APP_CLIENT_ID: '5efnhcfmnoabcfssqho8uag3uo', // your app client id
  COGNITO_DOMAIN: COGNITO_DOMAIN, // your cognito domain
  REDIRECT_SIGN_IN: `${APP_URL}/auth/login`,
  REDIRECT_SIGN_OUT: APP_URL,
  RESPONSE_TYPE: 'code', // for Authorization code grant
};
