// src/utils/auth.ts
import { cognitoConfig } from './config';

export async function exchangeCodeForTokens(code: string) {
  const { APP_CLIENT_ID, COGNITO_DOMAIN, REDIRECT_SIGN_IN } = cognitoConfig;
  const url = `${COGNITO_DOMAIN}/oauth2/token`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: APP_CLIENT_ID,
      code: code,
      redirect_uri: REDIRECT_SIGN_IN,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to exchange code for tokens');
  }

  return response.json();
}
