import { useEffect, useState } from 'react';
import { Navbar, ParkCard, SearchBar } from '../components';
import { Park } from '../types';
import { apiGet } from '../api';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

export const SearchParks = () => {
  const [allParks, setAllParks] = useState<Park[]>([]);
  const [parksToDisplay, setParksToDisplay] = useState<Park[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const fetchParks = async () => {
      try {
        const data: Park[] = await apiGet('/park');
        setAllParks(data);
        setParksToDisplay(data);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchParks();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  useEffect(() => {
    console.log('Search Value:', searchValue);
    if (!searchValue) {
      setParksToDisplay(allParks);
    } else {
      const filteredParks = allParks.filter((park) => {
        return (
          park.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          park.city.toLowerCase().includes(searchValue.toLowerCase())
        );
      });

      setParksToDisplay(filteredParks);
    }
  }, [searchValue, setSearchValue]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="page-container">
      <Navbar />
      <div className="page-content-container">
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        {parksToDisplay?.length ? (
          parksToDisplay.map((park) => {
            return <ParkCard key={park.id} park={park} />;
          })
        ) : (
          <h1 style={{ color: 'black' }}>No Parks Found</h1>
        )}
      </div>
    </div>
  );
};
