import { Navbar } from '../components';

export const Account = () => {
  return (
    <div className="page-container">
      <Navbar />

      <div className="page-content-container">
        <div className="login-container">
          <h1 id="form-title">Account</h1>
          <hr className="divider-top"></hr>
        </div>
      </div>
    </div>
  );
};
