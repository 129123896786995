// src/components/Callback.tsx
import { useEffect } from 'react';
import { exchangeCodeForTokens } from '../auth/token';
import { useLocation } from 'react-router-dom';

export const AuthLoginCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');

      if (code) {
        try {
          const tokens = await exchangeCodeForTokens(code);
          localStorage.setItem('gamesetbook_id_token', tokens.id_token);
          localStorage.setItem('gamesetbook_access_token', tokens.access_token);
          localStorage.setItem('gamesetbook_refresh_token', tokens.refresh_token);
          // Set History here with react-router-dom Action
        } catch (error) {
          console.error('Error exchanging code for tokens:', error);
        }
      }
    };

    handleCallback();
  });

  return <div>Loading...</div>;
};

export default AuthLoginCallback;
