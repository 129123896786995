/**
 * BookingStatus is a list of possible booking statuses used to manage
 * whether the booking is pending, confirmed or cancelled.
 */
export enum BookingStatus {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
}

/**
 * BookingDurations is a list of available booking durations in minutes.
 */
export type BookingDurationMinutes = 30 | 60 | 90 | 120;

export interface Booking {
  id: string; // Google Calendar Event ID
  timeRequested: string;
  timeConfirmed?: string;
  userEmail: string;
  parkId: string;
  courtId: string;
  calendarId: string; // Google Calendar Id
  start: string;
  end: string;
  duration: BookingDurationMinutes;
  status: BookingStatus;
}
