import { SERVER_URL } from '../constants';
import { Booking } from '../types';

export const apiGet = async (route: string) => {
  const response = await fetch(`${SERVER_URL}${route}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }
  console.dir(response, { depth: null });
  return await response.json();
};

export const apiPost = async (route: string, data: any): Promise<Booking | null> => {
  const url = `${SERVER_URL}${route}`;

  const options: RequestInit = {
    method: 'POST', // Specify the request method
    headers: {
      'Content-Type': 'application/json', // Set the request headers
    },
    body: JSON.stringify(data), // Convert the booking data to a JSON string
  };

  try {
    console.log(`Sending post request to ${url}`, data);
    const response = await fetch(url, options); // Make the fetch request

    if (!response.ok) {
      console.error(`Post request ${route} failed: ${response.status} ${response.statusText}`);
      return null;
    }

    const responseData = await response.json(); // Parse the JSON response
    console.log(`Post request ${route} successful`, responseData);
    return responseData as Booking;
  } catch (error) {
    console.error('Post Request Error:', error);
    throw error;
  }
};

export const apiDelete = async (route: string, data: any): Promise<Booking | null> => {
  const url = `${SERVER_URL}${route}`;

  const options: RequestInit = {
    method: 'DELETE', // Specify the request method
    headers: {
      'Content-Type': 'application/json', // Set the request headers
    },
    body: JSON.stringify(data), // Convert the booking data to a JSON string
  };

  try {
    console.log(`Sending delete request to ${url}`, data);
    const response = await fetch(url, options); // Make the fetch request

    if (!response.ok) {
      console.error(`Delete request ${route} failed: ${response.status} ${response.statusText}`);
      return null;
    }

    const responseData = await response.json(); // Parse the JSON response
    console.log(`Delete request ${route} successful`, responseData);
    return responseData as Booking;
  } catch (error) {
    console.error('Delete Request Error:', error);
    throw error;
  }
};
