import React from 'react';
import ReactDOM from 'react-dom';
import './styles/App.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  Account,
  AuthLoginCallback,
  Availability,
  CancelBooking,
  ConfirmBooking,
  Error,
  SearchParks,
} from './views';
import { AppRoutes } from './constants';
import { RecoilRoot } from 'recoil';

const router = createBrowserRouter([
  {
    path: AppRoutes.SearchParks,
    element: <SearchParks />,
    errorElement: <Error />,
  },
  {
    path: AppRoutes.Account,
    element: <Account />,
    errorElement: <Error />,
  },
  {
    path: AppRoutes.Availability,
    element: <Availability />,
    errorElement: <Error />,
  },
  {
    path: AppRoutes.Confirm,
    element: <ConfirmBooking />,
    errorElement: <Error />,
  },
  {
    path: AppRoutes.Cancel,
    element: <CancelBooking />,
    errorElement: <Error />,
  },
  {
    path: AppRoutes.AuthLogin,
    element: <AuthLoginCallback />,
    errorElement: <Error />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
